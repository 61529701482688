<template>
  <b-modal
    headerClass="border-bottom-0"
    footerClass="border-top-0 pt-0"
    modal-class="modalRegister modalRegisterRep modalUpdateInfo"
    v-model="modalChangePasswordData"
    @hide="onCloseModal"
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 modalRegisterHeader">Đổi mật khẩu</p>
    </template>
    <div class="d-flex flex-column justify-content-between w-100 modalRegisterContent mb-lg-1">
      <div>
        <form ref="form" v-on:keyup.enter="handlerChangePassword()">
          <!-- current Password -->
          <b-form-group>
            <label for="currentPassword">Mật khẩu hiện tại</label>
            <b-form-input
              name="currentPassword"
              type="password"
              v-validate="'required|min:8|max:16'"
              data-vv-as="Mật khẩu hiện tại"
              aria-describedby="password-help-block"
              v-model="currentPassword"
              :class="{ input: true, 'is-danger': errors.has('currentPassword') }"
            ></b-form-input>
            <span v-show="errors.has('currentPassword')" class="is-danger-text position-relative">
              {{ errors.first('currentPassword') }}
            </span>
          </b-form-group>
          <!-- Phone Number -->
          <b-form-group>
            <div class="modalRegisterLabel">
              <label for="newPassword">Mật khẩu mới</label>
            </div>
            <b-form-input
              name="newPassword"
              type="password"
              ref="newPassword"
              v-validate="'required|min:8|max:16'"
              data-vv-as="Mật khẩu"
              aria-describedby="password-help-block"
              v-model="newPassword"
              :class="{ input: true, 'is-danger': errors.has('newPassword') }"
              id="newPassword"
            ></b-form-input>
            <span v-show="errors.has('newPassword')" class="is-danger-text position-relative">
              {{ errors.first('newPassword') }}
            </span>
          </b-form-group>
          <!-- PassPort -->
          <b-form-group>
            <label for="newPasswordConfirm">Nhập lại mật khẩu mới</label>
            <b-form-input
              name="newPasswordConfirm"
              type="password"
              data-vv-as="Mật khẩu nhập lại"
              v-validate="'required|confirmed:newPassword'"
              aria-describedby="password-help-block"
              :class="{ input: true, 'is-danger': errors.has('newPasswordConfirm') }"
              v-model="newPasswordConfirm"
            ></b-form-input>
            <span v-show="errors.has('newPasswordConfirm')" class="is-danger-text position-relative">{{
              errors.first('newPasswordConfirm')
            }}</span>
          </b-form-group>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="h-100 modalRegisterFooter">
        <div class="justify-content-center">
          <div class="w-100">
            <button
              @click="handlerChangePassword()"
              class="btn w-100 text-uppercase btnConfirm position-relative test"
              :disabled="isDisabledReset"
              :class="{ 'opacity-7 cursor-none': isDisabledReset }"
            >
              CẬP NHẬT
            </button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AccountService from '../../api/account';
const Swal = require('sweetalert2');
export default {
  props: {
    modalChangePassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    };
  },
  computed: {
    modalChangePasswordData: {
      get() {
        return this.modalChangePassword;
      },
      set(newVal) {
        this.$emit('setModalChangePassword', newVal);
      }
    },
    isDisabledReset() {
      return (
        this.errors.has('currentPassword') ||
        !this.newPassword ||
        this.errors.has('newPassword') ||
        !this.newPasswordConfirm ||
        this.errors.has('newPasswordConfirm')
      );
    }
  },
  methods: {
    handlerChangePassword() {
      if (this.newPassword != this.newPasswordConfirm || !this.newPasswordConfirm || !this.newPassword) {
        return;
      }
      let id = this.$store.getters.getterAccountId;
      var data = {
        password: this.currentPassword,
        new_password: this.newPassword
      };

      AccountService.changePassword(id, data)
        .then((resp) => {
          if (resp.status) {
            Swal.fire({
              icon: 'success',
              title: 'Đổi mật khẩu thành công',
              showConfirmButton: false,
              timer: 2000
            });
            this.onCloseModal();
          }
        })
        .catch((e) => {
          let code = e.response.data.data.code;
          if (code === '002.001.003') {
            let field = 'currentPassword';
            this.errors.add({
              field,
              msg: e.response.data.data.message
            });
          }
          if (code === '002.001.029') {
            let field = 'newPassword';
            this.errors.add({
              field,
              msg: e.response.data.data.message
            });
          }
        });
    },
    onCloseModal() {
      this.modalChangePasswordData = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
    }
  },
  watch: {
    modalChangePasswordData: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>

<style lang="scss">
.modalRegisterFooterTxt > a {
  color: #0d234a;
}
.modalRegisterHeader {
  color: #0d234a;
}
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.modalRegisterFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}
</style>
