<template>
  <b-modal
    headerClass="border-bottom-0"
    footerClass="border-top-0 pt-0"
    modal-class="modalRegister modalRegisterRep modalUpdateInfo"
    @hide="onCloseModal"
    v-model="modalUpdateInformationData"
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 modalRegisterHeader">Cập nhật thông tin</p>
    </template>

    <div class="d-flex flex-column justify-content-between w-100 modalRegisterContent mb-lg-1">
      <form autocomplete="off" ref="form" v-on:keyup.enter="validateAndChangeUserProfile()">
        <!-- User Name -->
        <b-form-group class="modalUpdateInfoFormGroup">
          <label for="userNameInput">Họ và tên</label>
          <!-- <p>{{ this.userName }}</p> -->
          <b-form-input
            trim
            data-vv-as="Họ và tên"
            :class="{ input: true, 'is-danger': errors.has('userName') }"
            v-validate="{ required: true, min: 3, regex: /^\S[^`~!@#$%^&*()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/ }"
            id="userNameInput"
            name="userName"
            type="text"
            v-model="userName"
          ></b-form-input>
          <span v-show="errors.has('userName')" class="is-danger-text position-relative">{{
            errors.first('userName')
          }}</span>
        </b-form-group>
        <!-- Phone Number -->
        <b-form-group class="modalUpdateInfoFormGroup">
          <label for="phoneNumberInput">Số điện thoại</label>
          <b-form-input
            trim
            id="phoneNumberInput"
            name="phoneNumber"
            type="number"
            :value="this.getPhone"
            :disabled="this.modalUpdateInformation"
          ></b-form-input>
        </b-form-group>
        <!-- PassPort -->
        <b-form-group>
          <label for="passportInput">Số CMND/CCCD</label>
          <b-form-input
            trim
            data-vv-as="CMND/CCCD"
            :class="{ input: true, 'is-danger': errors.has('passport') }"
            v-validate="{ regex: /^([0-9]{9}|[0-9]{12})$/, required: true }"
            id="passportInput"
            name="passport"
            type="number"
            v-model="passport"
          ></b-form-input>
          <span v-show="errors.has('passport')" class="is-danger-text position-relative">{{
            errors.first('passport') ? 'CMND/CCCD chỉ 9 hoặc 12 kí tự' : ''
          }}</span>
        </b-form-group>

        <!-- Date -->
        <!-- HIDE BIRTHDAY -->
        <div>
          <b-form-group id="group-dob" label="Ngày sinh" label-for="input-dob" class="modalUpdateInfoFormGroup ">
            <div class="justify-content-center position-relative">
              <UIDateTimePickerCommon
                :isMobile="isMobile"
                :title="'Ngày sinh'"
                :date_of_birth="date_of_birth"
                :maxDateDesktop="maxDateDesktop"
                :maxDateMobile="maxDateMobile"
                :minYear="1920"
                :minDateDesktop="new CustomDate('1920')"
                :defaultSelectValue="'20/01/2000'"
                @handlerInputCalendar="handlerInputCalendar"
              ></UIDateTimePickerCommon>
              <span v-show="errors.has('errorCommon')" class="is-danger-text position-relative">{{
                errors.first('errorCommon')
              }}</span>
            </div>
          </b-form-group>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer>
      <div class="h-100 modalRegisterFooter">
        <div class="justify-content-center">
          <div class="w-100">
            <button
              @click="validateAndChangeUserProfile()"
              :disabled="disableBtn"
              class="btn w-100 text-uppercase btnConfirm position-relative"
            >
              <span>Cập nhật</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AccountService from '../../api/account';
import UIDateTimePickerCommon from '../../components/UI/DateTimePicker/UIDateTimePickerCommon';
const Swal = require('sweetalert2');
export default {
  components: {
    UIDateTimePickerCommon
  },
  props: {
    modalUpdateInformation: {
      type: Boolean,
      default: false
    },
    userInfo: Object
  },
  data() {
    return {
      userName: this.userInfo.name,
      passport: this.userInfo.passport,
      date_of_birth: this.userInfo.date_of_birth
      // minuteListValue: this.formatDate(this.userInfo.date_of_birth, 'vi')
    };
  },
  beforeCreate() {},
  created() {
    this.$validator.validateAll();
  },
  watch: {
    userInfo: function(newVal) {
      if (newVal) {
        this.userInfo = newVal;
        this.userName = newVal.name;
        this.passport = newVal.passport;
        this.date_of_birth = newVal.date_of_birth;
        // this.minuteListValue = this.formatDate(newVal.date_of_birth, 'vi');
      }
    },
    modalUpdateInformationData: function(newVal) {
      this.handleToggleModal(newVal);
    }
  },
  mounted() {},
  computed: {
    maxDateMobile() {
      var d = new CustomDate(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear() - 18;

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    maxDateDesktop() {
      return new CustomDate();
    },
    isMobile() {
      return window.innerWidth <= this.getMobileThresshold();
    },
    modalUpdateInformationData: {
      get() {
        return this.modalUpdateInformation;
      },
      set(newVal) {
        this.$emit('setModalUpdateInformation', newVal);
      }
    },
    disableBtn() {
      return this.errors.has('passport') || !this.userName || !this.passport;
    },
    getName() {
      return this.$store.getters.getterUserInfo.name;
    },
    getPhone() {
      return this.$store.getters.getterUserInfo.phone;
    },
    getPassport() {
      return this.$store.getters.getterUserInfo.passport;
    },
    getDate_of_birth() {
      return this.$store.getters.getterUserInfo.date_of_birth;
    }
  },
  methods: {
    handlerInputCalendar(newVal) {
      this.date_of_birth = newVal;
    },
    validateAndChangeUserProfile() {
      // let data = new CustomDate(new CustomDate(new CustomDate().getFullYear() - 18, new CustomDate().getMonth(), new CustomDate().getDate()));

      // if (data.getTime())
      // if (this.date_of_birth === 'notActived') {
      //   this.errors.add({
      //     field: 'errorCommon',
      //     msg: 'Ngày sinh không hợp lệ'
      //   });
      //   this.$bus.$emit('handlerErrorCommon', { msg: 'Ngày sinh không hợp lệ' });
      //   return;
      // }
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.any()) return;
        this.changeUserProfile();
      });
    },
    changeUserProfile() {
      let id = this.$store.getters.getterAccountId;
      var data = {
        name: this.userName,
        passport: this.passport
      };
      if (this.date_of_birth) data.date_of_birth = this.date_of_birth;

      AccountService.changeUserInfo(id, data)
        .then((resp) => {
          let result = resp.data.data;
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Cập nhật tài khoản thành công',
              showConfirmButton: false,
              timer: 2000
            });
            this.onCloseModal();
            let timerSet = resp.data.data.date_of_birth;
            if (timerSet) {
              timerSet = new CustomDate(timerSet);
              timerSet.setHours(7);
            }
            data = {
              ...resp.data.data,
              date_of_birth: timerSet,
              phone: this.userInfo.phone,
              lastName: resp.data.data.name ? resp.data.data.name.split(' ').pop() : ''
            };
            this.$store.commit('setUserInfo', data);
            // let userName = resp.data.data.name.split(' ');
            // this.$store.commit('setLastname', userName ? userName.pop() : '');
          }
        })
        .catch((err) => {
          let code = err.response.data.data.code;
          if (code) {
            Swal.fire({
              icon: 'error',
              title: 'Vui lòng điền đầy đủ thông tin!',
              showConfirmButton: true,
              timer: 2000
            });
          }
        });
    },
    onCloseModal() {
      this.modalUpdateInformationData = false;
    }
  }
};
</script>

<style lang="scss">
.modalRegisterFooterTxt > a {
  color: #0d234a;
}
.modalRegisterHeader {
  color: #0d234a;
}
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.modalUpdateInfoFormGroup {
  #phoneNumberInput {
    background-color: #d8d8d8 !important;
  }
  .iconTimeDial {
    background-image: url('../../assets/img/icon/icon_calendar.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    right: 3px;
    top: 12px;
    cursor: pointer;
  }
}
.modalRegisterFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}
.vc-w-full {
  width: 310px;
  .vc-popover-content-wrapper {
    transform: translate3d(-95px, 27px, 0px) !important;
  }
  .vc-nav-container {
    width: 265px;
  }
  .vc-w-12 {
    width: 68px !important;
    height: 35px !important;
  }
}
@media (max-width: 320px) {
  .vc-w-full {
    width: 280px;
  }
}
</style>
