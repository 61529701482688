<template>
  <div :class="{ profileUser: true, 'd-none': isRoot(), 'd-md-block': true }">
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Chi tiết tài khoản</div>
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content">
      <div class="content-container method-container">
        <div class="content-title">Thông tin tài khoản</div>
        <div class="bgWhite profileUserContent">
          <div class="row">
            <div class="col-lg-3 col-sm-6 col-12">
              <p class="text-uppercase">họ và tên</p>
              <h6>
                {{ this.getName }}
              </h6>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <p class="text-uppercase">số điện thoại</p>
              <h6 v-if="censorStatus.phone">
                <span>{{ censorData(this.getPhone) }}</span>
                <img
                  src="@/assets/img/icon/icon_showpass_on.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('phone', false)"
                />
              </h6>
              <h6 v-else>
                <span>{{ this.getPhone }}</span>
                <img
                  src="@/assets/img/icon/icon_showpass_off.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('phone', true)"
                />
              </h6>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <p class="text-uppercase">Số CMND/CCCD</p>
              <h6 v-if="censorStatus.passport">
                <span>{{ censorData(this.getPassport, 0) }}</span
                ><img
                  src="@/assets/img/icon/icon_showpass_on.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('passport', false)"
                  v-if="this.getPassport && this.getPassport.length"
                />
              </h6>
              <h6 v-else>
                <span>{{ this.getPassport }}</span>
                <img
                  src="@/assets/img/icon/icon_showpass_off.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('passport', true)"
                  v-if="this.getPassport && this.getPassport.length"
                />
              </h6>
            </div>
            <!-- HIDE BIRTHDAY -->
            <div class="col-lg-3 col-sm-6 col-12">
              <p class="text-uppercase">Ngày sinh</p>
              <h6 v-if="censorStatus.date_of_birth">
                <span>{{
                  !this.getDate_of_birth ? '' : censorData(getFulltime(this.getDate_of_birth, 'DD/MM/YYYY'), 0)
                }}</span
                ><img
                  src="@/assets/img/icon/icon_showpass_on.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('date_of_birth', false)"
                  v-if="this.getDate_of_birth"
                />
              </h6>
              <h6 v-else>
                <span>{{ !this.getDate_of_birth ? '' : getFulltime(this.getDate_of_birth, 'DD/MM/YYYY') }}</span>
                <img
                  src="@/assets/img/icon/icon_showpass_off.svg"
                  alt=""
                  class="icon-censor"
                  @click="toggleCensorStatus('date_of_birth', true)"
                  v-if="this.getDate_of_birth"
                />
              </h6>
            </div>
          </div>
        </div>
        <div class="profileUserBtn">
          <button class="btn updateInformationBtn text-uppercase" @click="updateInformation()">
            cập nhật thông tin
          </button>
          <button
            v-if="getUserInfo && getUserInfo.available_update_invite_code"
            class="btn updateInformationBtn text-uppercase"
            @click="updateInviteCode()"
          >
            Cập nhật mã giới thiệu
          </button>
          <button class="btn text-uppercase" v-if="hasPassword" @click="changePassword()">đổi mật khẩu</button>
          <button class="btn text-uppercase" v-else @click="createPassword()">tạo mật khẩu</button>
        </div>
        <!-- <div v-if="!checkHideAssociateProduction" class="profileUserAssociate mt-4 pt-2">
          <p class="content-title">Liên kết tài khoản</p>
          <p class="color-black">Liên kết tài khoản giúp bạn đăng nhập nhanh chóng, đơn giản và bảo mật hơn</p>
          <div v-for="(item, index) in listAccountLink" :key="'oauth_' + index" class="profileUserAssociate__box">
            <div class="d-flex align-items-center">
              <img class="mr-2" :src="item.image_url" width="32" height="32" />
              <p class="font-weight-600">{{ item.name }}</p>
            </div>
            <button v-if="!item.linked" @click="onLoginAssociate()" class="btn profileUserAssociate__button">
              <span>Liên kết</span>
            </button>
            <button v-else @click="onUnlinkAccount(item)" class="btn profileUserAssociate__button">
              <span>Huỷ liên kết</span>
            </button>
          </div>
        </div> -->
        <CommonModal
          :title="'Xác nhận'"
          @updateOpen="setModalConfirmUnlink"
          :isOpen="modalConfirmItem"
          @handleConfirm="handleConfirmUnlink"
          :isConfirmBtnLoading="isLoadingConfirm"
          :isCloseWhenConfirm="false"
          :isCancleBtnDisable="isLoadingConfirm"
          :cancleTextBtn="'Để sau'"
          :confirmTextBtn="'Đồng ý'"
        >
          <div class="modalConfirm text-center py-4" v-html="messageConfirm"></div>
        </CommonModal>
        <modal-update-information
          @setModalUpdateInformation="setModalUpdateInformation"
          :modalUpdateInformation="modalUpdateInformation"
          :userInfo="getUserInfo"
        />
        <modal-change-password
          @setModalChangePassword="setModalChangePassword"
          :modalChangePassword="modalChangePassword"
        />
        <ModalCreatePassword
          :modalCreatePassword="modalCreatePassword"
          :hasNotice="hasNotice"
          :noticeTitle="noticeTitle"
          @onCreatePasswordSuccess="onCreatePasswordSuccess"
          @updateModal="onUpdateModalCreatePassword"
        />

        <CommonModal
          title="Cập nhật mã giới thiệu"
          @updateOpen="isOpenUpdateInvite = $event"
          :isOpen="isOpenUpdateInvite"
          @handleConfirm="handleConfirmUpdateInvite"
          :isConfirmBtnLoading="isLoadingUpdateInvite"
          :isCancleBtnDisable="isLoadingUpdateInvite"
          :isConfirmBtnDisable="isDisableUpdateInvite"
          cancleTextBtn="Để sau"
          confirmTextBtn="Cập nhật"
          @handleHide="inviteCode = ''"
        >
          <form autocomplete="off" ref="form" v-on:keyup.enter="validateAndChangeUserProfile()">
            <b-form-group class="modalUpdateInfoFormGroup">
              <label for="inviteCode">Mã giới thiệu</label>
              <b-form-input
                trim
                data-vv-as="mã giới thiệu"
                :class="{ input: true, 'is-danger': errors.has('inviteCode') }"
                v-validate="{ required: true }"
                id="inviteCode"
                name="inviteCode"
                type="text"
                v-model="inviteCode"
                style="text-transform: uppercase"
              ></b-form-input>
              <span v-show="errors.has('inviteCode')" class="is-danger-text position-relative">{{
                errors.first('inviteCode')
              }}</span>
            </b-form-group>
          </form>
        </CommonModal>
      </div>
    </div>
  </div>
</template>

<script>
import ModalUpdateInformation from './ModalUpdateInformation';
import ModalChangePassword from './ModalChangePassword';
import ModalCreatePassword from './ModalCreatePassword';
import AccountService from '@/api/account';
import CommonModal from '../CommonModal.vue';
import { Noti } from '../../main';
const Swal = require('sweetalert2');
export default {
  components: { ModalUpdateInformation, ModalChangePassword, CommonModal, ModalCreatePassword },
  data() {
    return {
      modalUpdateInformation: false,
      modalChangePassword: false,
      listAccountLink: [],
      timerWaiting: null,
      modalConfirmItem: false,
      isLoadingConfirm: false,
      messageConfirm: '',
      unlinkSource: null,
      modalCreatePassword: false,
      hasNotice: true,
      noticeTitle: 'Bạn cần tạo mật khẩu cho tài khoản để thực hiện yêu cầu này.',
      onCreatePasswordSuccess: () => {},
      censorStatus: {
        phone: true,
        date_of_birth: true,
        passport: true
      },
      isOpenUpdateInvite: false,
      inviteCode: '',
      isLoadingUpdateInvite: false
    };
  },
  watch: {
    userInfo: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.userInfo = newVal;
      }
    }
  },
  beforeCreate() {},
  created() {
    this.getListAccountLink();
  },
  mounted() {},
  computed: {
    hasPassword() {
      return (
        this.$store.getters.getterUserProfile.hasPassword ||
        typeof this.$store.getters.getterUserProfile.hasPassword === 'undefined'
      );
    },
    objOauth2() {
      return this.$store.getters.getterObjOauth2;
    },
    getName() {
      return this.$store.getters.getterUserInfo.name;
    },
    getPhone() {
      return this.$store.getters.getterUserInfo.phone;
    },
    getPassport() {
      return this.$store.getters.getterUserInfo.passport;
    },
    getDate_of_birth() {
      return this.$store.getters.getterUserInfo.date_of_birth;
    },
    getUserInfo() {
      return this.$store.getters.getterUserInfo;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    infoOauth2() {
      return this.$store.getters.getterInfoOauth2;
    },
    tokenInfoOauth2() {
      return this.$store.getters.getterTokenInfoOauth2;
    },
    isErrorPopupOauth2: {
      get() {
        return this.$store.getters.getterIsErrorPopupOauth2;
      },
      set(newVal) {
        this.$store.commit('setIsErrorPopupOauth2', newVal);
      }
    },
    isDisableUpdateInvite() {
      return this.errors.first('inviteCode') || !this.inviteCode;
    }
  },
  methods: {
    toggleCensorStatus(key, value) {
      this.censorStatus = { ...this.censorStatus, [key]: value };
    },
    onUpdateModalCreatePassword(newVal) {
      this.modalCreatePassword = newVal;
    },
    createPassword() {
      this.noticeTitle = 'Vui lòng tạo mật khẩu cho tài khoản.';
      this.modalCreatePassword = true;
      this.onCreatePasswordSuccess = () => {
        Swal.fire({
          icon: 'success',
          title: 'Tạo mật khẩu cho tài khoản thành công',
          showConfirmButton: false,
          timer: 2000
        });
        this.modalCreatePassword = false;
      };
    },
    handleConfirmUnlink() {
      this.isLoadingConfirm = true;
      AccountService.postUnlinkAccount(this.accountId, this.unlinkSource)
        .then((resp) => {
          if (resp && resp.status == 204) {
            this.getListAccountLink();
            this.setModalConfirmUnlink(false);
            Swal.fire({
              icon: 'success',
              title: 'Hủy liên kết tài khoản thành công',
              showConfirmButton: false,
              timer: 2000
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoadingConfirm = false;
          this.modalCreatePassword = false;
        });
    },
    onUnlinkAccount(linkSource) {
      this.unlinkSource = linkSource.source;
      if (this.hasPassword) {
        this.messageConfirm = `Bạn muốn hủy kết nối tài khoản với ${linkSource.name}?`;
        this.setModalConfirmUnlink(true);
      } else {
        this.modalCreatePassword = true;
        this.noticeTitle = 'Bạn cần tạo mật khẩu cho tài khoản để thực hiện yêu cầu này.';
        this.onCreatePasswordSuccess = this.handleConfirmUnlink;
      }
    },
    setModalConfirmUnlink(newVal) {
      this.modalConfirmItem = newVal;
    },
    getListAccountLink() {
      AccountService.getListAccountLink(this.accountId)
        .then((resp) => {
          if (resp && resp.data && resp.data.data) {
            this.listAccountLink = resp.data.data.source_list;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async onLoginAssociate() {
      await this.onAssociateOauth(
        window.location.href,
        this.objOauth2.decodeUrlSaveextra,
        this.$store.getters.getterTokenInfoOauth2,
        this.$store.getters.getterInfoOauth2
      );
    },
    updateInformation() {
      this.modalUpdateInformation = true;
    },
    updateInviteCode() {
      this.isOpenUpdateInvite = true;
    },
    setModalUpdateInformation(newVal) {
      this.modalUpdateInformation = newVal;
    },
    changePassword() {
      this.modalChangePassword = true;
    },
    setModalChangePassword(newVal) {
      this.modalChangePassword = newVal;
    },
    isRoot() {
      return this.$route.path == '/tai-khoan/' || this.$route.path == '/tai-khoan';
    },
    handleConfirmUpdateInvite() {
      this.isLoadingUpdateInvite = true;
      AccountService.updateInviteCode(this.accountId, this.inviteCode)
        .then(() => {
          Noti.fire({
            icon: 'success',
            title: 'Cập nhật mã giới thiệu thành công',
            showConfirmButton: false,
            timer: 2000
          });

          this.getUserInfoLogin();
        })
        .catch((error) => {
          const message =
            error.response?.data?.data?.message || 'Cập nhật mã giới thiệu không thành công. Vui lòng thử lại.';
          Noti.fire({
            icon: 'error',
            title: message,
            showConfirmButton: false,
            timer: 2000
          });
        })
        .finally(() => {
          this.isLoadingUpdateInvite = false;
        });
    }
  }
};
</script>

<style lang="scss">
.profileUser {
  .profileUserContent {
    border-radius: 3px;
    .row {
      border-radius: 3px;
      margin-right: 0;
      margin-left: 0;
      padding-top: 19px;
      padding-bottom: 12px;
      p {
        font-size: 14px;
        color: #666666;
      }
      h6 {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .profileUserBtn {
    padding-top: 21px;
    .btn {
      background-color: #0d234a;
      font-size: 15px;
      font-weight: 600;
      color: white;
      padding: 10px 15px 10px 16px;
    }
    .updateInformationBtn {
      margin-right: 10px;
    }
  }
}

.profileUserAssociate {
  &__box {
    height: 60px;
    margin-top: 10px;
    width: 100%;
    padding: 10px 10px 10px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    height: 40px;
    padding: 10px 19px 10px 18px;
    border-radius: 3px;
    background-color: #e5edf5;
    text-align: center;
    color: #00509d;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.3s all;
    font-size: 0.9375rem;
    &:hover {
      background-color: #dae6f1;
      color: #00509d;
    }
  }
}
@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .profileUser {
    .profileUserContent {
      .row {
        div:nth-child(1) {
          padding-left: 21px;
        }
      }
    }
  }
}
@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .profileUser {
    .content-title {
      font-size: 18px !important;
    }
    .profileUserContent {
      border-radius: 3px;
      .row {
        div:nth-child(1) {
          padding-left: 11px;
        }
        .col-lg-3 {
          padding-left: 11px;
        }
      }
    }
    .profileUserBtn {
      padding-top: 19px;
      .btn {
        width: 100%;
      }
      .updateInformationBtn {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
